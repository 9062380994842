/* header.css */

/* General styling for the home button */
.home-button {
    background-color: #1ABC9C;
    color: white;
    border: none;
    padding: 4px 14px;
    margin-right: 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    display: inline-block;
    text-align: center;
}
  
  /* Hover effect for the home button */
  .home-button:hover {
    background-color: #0056b3; /* Darker shade for hover effect */
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .home-button {
      padding: 8px 16px;
      font-size: 14px;
    }
  }
  
  @media (max-width: 576px) {
    .home-button {
      padding: 6px 12px;
      font-size: 12px;
    }
  }
  
  /* Position the home button before the welcome text */
  .navbar > div {
    display: flex;
    align-items: center;
  }
  
  .welcome-text {
    margin-left: 10px; /* Space between the home button and welcome text */
  }
  