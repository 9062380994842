.profile-card {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,.1);
  margin-bottom: 20px;
}

.profile-photo {
  border-radius: 5px;
  margin-right: 20px;
}

.profile-details {
  flex-grow: 1;
}

.search-bar {
  display: flex;
  align-items: center;
  max-width: 800px;
  margin: 20px auto;
  padding: 0 10px;
  border: 1px solid #000;
}

.search-input {
  height: 38px;
  flex-grow: 1;
  border: none;
  padding: 5px 10px;
}

.search-button, .filter-button {
  background-color: transparent;
  border: none;
  padding: 5px 15px;
  cursor: pointer;
  font-size: 24px;
  color: black;
}

.filter-sidebar {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background: #fff;
  box-shadow: -2px 0 5px rgba(0,0,0,.3);
  z-index: 100;
  padding: 20px;
  overflow-y: auto; 
}

.filter-sidebar.show {
  display: block;
}

.filter-sidebar form {
  margin-bottom: 20px;
}

.filter-sidebar label {
  display: block;
  margin-bottom: 5px;
}

.filter-sidebar input {
  width: calc(100% - 20px);
  padding: 5px;
  margin-bottom: 10px;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.reset-button {
  background-color: transparent;
  color: #dc3545;
  border: none;
  padding: 0;
  cursor: pointer;
}

.close-btn {
  position: absolute;
  top: 20px;
  cursor: pointer;
}

.close-btn i {
  font-size: 24px;
}

h2 {
  margin-left: 40px;
}

/* Employee Search */
.search-form {
  background-color: #eae9ff;
  padding: 30px 60px 35px 50px;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  display: contents;
}

.search-form label {
  margin-bottom: 10px;
}

.search-form input[type="text"],
.search-form input[type="number"] {
  width: 100%;
  padding: 8px;
  height: 45px;
  border: 1px solid #ccc;
  border-radius: 6px;
  margin: 10px 0 10px 0;
}

.search-form button {
  width: 20%;
  padding: 8px;
  background-color: #8a2be2;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 16px;
  margin: 0 auto;
  display: block;
}

.salary {
  display: flex;
  gap: 10px;
}

input#min-salary,
input#max-salary {
  width: 100px;
  height: 25px;
}

.text-2 {
  margin-top: 20px;
}

/* Labour Law */
.pdf-container {
  margin: 30px 0 0 20px;
  width: fit-content;
  border: 1px solid purple;
  padding: 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  transition: box-shadow 0.3s; 
}

.pdf-container:hover {
  box-shadow: 
      -10px -10px 5px -2px #C272FF,
      10px -10px 10px -2px #C272FF,
      0 -10px 5px -2px #C272FF,
      0 0 0 1px rgba(0, 0, 0, 0.1); 
}

.pdf-logo {
  font-size: 24px;
  color: red;
}

.pdf-link {
  text-decoration: none;
  color: black;
  padding-left: 10px;
  font-size: 18px;
  font-weight: 600;
}

.pdf-link:hover {
  text-decoration: underline;
}

/* Training and blog */
.tab-container { 
  padding: 20px; 
}

.tab-buttons { 
  margin-left: -5px;
  display: flex; 
  justify-content: space-around; 
  padding-bottom: 10px; 
}

.tab {
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
  width: 330px;
  padding: 40px 0 20px 0;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  height: 40px;
  border-radius: 15px;
}

.tab.active { 
  border: 2px solid rgb(220, 129, 220); 
}

.content-container { 
  display: none; 
}

.content-container.active { 
  display: flex; 
  flex-wrap: wrap; 
  justify-content: space-between; 
}

.box {
  width: calc(29.333% - 10px);
  height: 150px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.tab:hover {
  box-shadow: 
    -7px -7px 2px -2px #C272FF,
    7px -7px 2px -2px #C272FF,
    0 -7px 2px -2px #C272FF,
    0 0 0 1px rgba(0, 0, 0, 0.1); 
}

/* Store Locator */
.container-lcator {
  background: #cecbf4;
  padding: 20px 20px 20px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 75%;
  margin: 20px auto; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.str-lbl {
  font-size: 18px;
  color: black;
  display: block;
  margin-bottom: 15px;
}

#currentLocation {
  width: 95%; 
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 19px;
  color: #666;
  margin-bottom: 15px;
  height: 35px;
}

.button-str-lcn {
  background: #7E57C2;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 17px;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  width: 30%; 
  border-radius: 20px;
  margin: 0 auto;
  margin-top: 15px; 
}

.button-str-lcn:hover {
  background: #673AB7;
}

.message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.message-container p {
  text-align: center;
  font-size: 18px;
  color: #007bff;
}

.profile-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.basic-info,
.work-info {
  width: 45%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.basic-info h2,
.work-info h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.basic-info p,
.work-info p {
  margin: 5px 0;
}

.btn-download {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}

.btn-download:hover {
  background-color: #0056b3;
}

/* Media Queries for Mobile Screens */

/* Existing CSS remains unchanged */

/* Media Queries */

/* Large tablets and small desktops */
@media only screen and (max-width: 1024px) {
  .container-lcator, .container-1, .container-3 {
    width: 90%;
  }

  .tab {
    width: 280px;
  }

  .box {
    width: calc(45% - 10px);
  }

  .profile-section {
    flex-direction: column;
  }

  .basic-info, .work-info {
    width: 100%;
    margin-bottom: 20px;
  }
}

/* Tablets */
@media only screen and (max-width: 768px) {
  .profile-card {
    flex-direction: column;
    align-items: center;
  }

  .profile-photo {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .search-bar {
    flex-direction: column;
    align-items: stretch;
  }

  .search-input {
    margin-bottom: 10px;
  }

  .filter-sidebar {
    width: 100%;
  }

  .search-form {
    padding: 20px;
  }

  .search-form button {
    width: 50%;
  }

  .tab-buttons {
    flex-direction: column;
  }

  .tab {
    width: 100%;
    margin-bottom: 10px;
  }

  .box {
    width: calc(50% - 10px);
  }

  .container-lcator, .container-1, .container-3 {
    width: 95%;
  }

  .button-str-lcn {
    width: 50%;
  }
}

/* Large smartphones */
@media only screen and (max-width: 600px) {
  .search-form button {
    width: 70%;
  }

  .box {
    width: 100%;
  }

  .button-str-lcn {
    width: 70%;
  }

  .pdf-container {
    margin: 20px 0;
    width: 90%;
  }
}

/* Small smartphones */
@media only screen and (max-width: 480px) {
  .container {
    width: 100%;
    padding: 10px;
  }

  h2 {
    margin-left: 20px;
  }

  .search-form input[type="text"],
  .search-form input[type="number"] {
    font-size: 16px;
  }

  .search-form button {
    width: 100%;
  }

  .salary {
    flex-direction: column;
    gap: 5px;
  }

  input#min-salary,
  input#max-salary {
    width: 100%;
  }

  .pdf-logo {
    font-size: 20px;
  }

  .pdf-link {
    font-size: 16px;
  }

  .tab {
    font-size: 15px;
    padding: 30px 0 15px 0;
  }

  .str-lbl {
    font-size: 16px;
  }

  #currentLocation {
    font-size: 16px;
  }

  .button-str-lcn {
    font-size: 15px;
    width: 100%;
  }

  .message-container p {
    font-size: 16px;
  }

  .basic-info h2,
  .work-info h2 {
    font-size: 18px;
  }

  .basic-info p,
  .work-info p {
    font-size: 14px;
  }

  .btn-download {
    width: 100%;
    text-align: center;
  }
}

/* Very small devices */
@media only screen and (max-width: 320px) {
  .pdf-container {
    padding: 10px;
  }

  .pdf-logo {
    font-size: 18px;
  }

  .pdf-link {
    font-size: 14px;
  }

  .tab {
    font-size: 14px;
    padding: 20px 0 10px 0;
  }

  .str-lbl {
    font-size: 14px;
  }

  #currentLocation {
    font-size: 14px;
  }

  .button-str-lcn {
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .profile-card {
    flex-direction: column;
    align-items: center;
  }

  .profile-photo {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .search-bar {
    flex-direction: column;
    align-items: stretch;
  }

  .search-input {
    margin-bottom: 10px;
  }

  .filter-sidebar {
    width: 100%;
  }

  .search-form {
    padding: 20px;
  }

  .search-form button {
    width: 100%;
  }

  .salary {
    flex-direction: column;
    gap: 5px;
  }

  input#min-salary,
  input#max-salary {
    width: 100%;
  }

  .pdf-container {
    margin: 20px 0;
    width: 100%;
  }

  .tab-buttons {
    flex-direction: column;
  }

  .tab {
    width: 100%;
    margin-bottom: 10px;
  }

  .box {
    width: 100%;
  }

  .container-lcator {
    width: 90%;
  }

  .button-str-lcn {
    width: 100%;
  }

  .profile-section {
    flex-direction: column;
  }

  .basic-info,
  .work-info {
    width: 100%;
    margin-bottom: 20px;
  }

  .btn-download {
    width: 100%;
    text-align: center;
  }
}

/* Additional Mobile-specific styles */
@media only screen and (max-width: 480px) {
  .container {
    width: 100%;
    padding: 10px;
  }
  
  .job-post-form{
    max-width: 75%;
  }

  h2 {
    margin-left: 20px;
  }

  .search-form input[type="text"],
  .search-form input[type="number"] {
    font-size: 16px;
  }

  .pdf-logo {
    font-size: 20px;
  }

  .pdf-link {
    font-size: 16px;
  }

  .tab {
    font-size: 15px;
    padding: 30px 0 15px 0;
  }

  .str-lbl {
    font-size: 16px;
  }

  #currentLocation {
    font-size: 16px;
  }

  .button-str-lcn {
    font-size: 15px;
  }

  .message-container p {
    font-size: 16px;
  }

  .basic-info h2,
  .work-info h2 {
    font-size: 18px;
  }

  .basic-info p,
  .work-info p {
    font-size: 14px;
  }
}