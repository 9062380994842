body, html {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }
  
  
  .reg-form {
    text-align: center;
    font-size: 26px;
    font-weight: 700;
    margin: 0; 
    width: 100%; 
    margin-right: 50px;
    line-height: 55px;
  }
  
  section {
    max-width: 900px; 
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 20px;
  }
  
  .registration-form {
    max-width: 800px;
    width: 100%;
    background-color: #d5d2ff;
    padding: 10px 20px 10px 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 17px;
}

  .form-container-reg{
    margin-left: 17em;;
  }
  
  .form-field {
    margin-bottom: 10px;
  }

  .form-field.date-input input[type='date'] {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    box-sizing: border-box;
    height: 55px;
}
  
  .form-field input[type='text'],
  .form-field input[type='email'],
  .form-field select,
  .form-field textarea {
    width: 100%;
    height: 55px;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
  }
  
  .form-field input[type='text']:focus,
  .form-field input[type='email']:focus,
  .form-field select:focus,
  .form-field textarea:focus {
    outline: none;
    border-color: #6658d3;
  }
  
  .form-field .location-input {
    position: relative;
  }
  
  .form-field .location-input input[type='text'] {
    padding-right: 34px;
  }
  
  .form-field .location-input .location-icon {
    position: absolute;
    right: 10px;
    top: 11px;
    cursor: pointer;
  }
  
  .form-field textarea {
    height: 80px;
    resize: none;
  }
  
  .form-button {
    background-color: #9b59b6;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 24px;
    cursor: pointer;
    width: 30%;
    height: 45px;
    margin-top: 20px;
    font-size: 1.2rem;
    display: block;
    margin: 0 auto; 
  }
  
  .form-button:hover {
    background-color: #5748b1;
  }
  
  .form-field.date-input {
    position: relative;
  }
  
  .form-field.date-input input[type='date'] {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  /* Media Queries */
  @media (max-width: 767px) {
    section {
      width: 80%; 
      margin-right: 70px;
    }
    h2{
      margin-right: 20px;
    }
    .back-arrow {
      display: none;
    }

    .form-container-reg {
      margin-left: 0px;
  }

  .form-field{
     max-width: 94%;
  }
  
  
  }
  