/* General Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.container-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fgt {
  margin-bottom: 10px;
  font-size: 21px;
  color: #333;
}

.forrgt-pass {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s, box-shadow 0.3s;
  margin-bottom: 11px;;
}

.forrgt-pass:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.email-text {
  margin-bottom: 20px;
  font-size: 1.1em;
  color: #666;
  text-align: center;
}

.input-box-reg {
  margin-bottom: 20px;
}

.submit-btn-1 {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 10px;
}

.submit-btn-1:hover {
  background-color: #0056b3;
}

.message {
  display: none;
  margin-top: 20px;
  font-size: 0.9em;
  color: #28a745;
  text-align: center;
}

/* Responsive Styles */
@media (max-width: 767px) {
  h2 {
    font-size: 1.5em;
  }

  .email-text {
    font-size: 1em;
  }

  .btn-primary {
    font-size: 0.9em;
  }

  .message {
    font-size: 0.8em;
  }
}

.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.text-success {
  color: green;
}

.text-danger {
  color: red;
}
