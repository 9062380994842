/* HospitalLocator.css */

.container-3 {
    margin-top: 20px;
  }
  
  #map {
    width: 100%;
    height: 500px;
    border: 2px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .locator {
    width: calc(100% - 110px);
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 16px;
  }
  
  .button-locator {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
  }
  
  .button-locator:hover {
    background-color: #0056b3;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
    font-weight: bold;
  }
  
  .hospital-list {
    margin-top: 20px;
  }
  
  .hospital {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .hospital h3 {
    margin: 0;
    font-size: 18px;
    color: #007bff;
  }
  
  .hospital p {
    margin: 5px 0;
    font-size: 14px;
    color: #555;
  }

  .map-container {
    height: 400px;
    border: 2px solid #333;
    border-radius: 8px;
    margin-top: 20px;
  }
  
  .leaflet-marker-icon {
    filter: invert(1);
  }
  
  