body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

.container-1 {
  background: #cecbf4;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 75%;
  margin: 20px auto; 
}

label {
  font-size: 18px;
  color: black;
  display: block;
  margin-bottom: 15px;
}

.currentLocation {
  width: 95%; 
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 19px;
  color: #666;
  margin-bottom: 15px;
  height: 35px;
}

.button-store {
  background: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 17px;
  border-radius: 20px;
  cursor: pointer;
  display: block;
  width: 30%; 
  margin: 15px auto 0;
}

.button-store:hover {
  background: #673AB7;
}

/* Training & Technology */

.container-2 {
  display: flex;
  align-items: center; 
  padding: 20px;
}

.sales, .technical {
  width: 25%; 
  background-color: rgb(249, 236, 253);
  padding: 40px 10px 20px 10px; 
  border: 1px solid #ccc; 
  height: 50px;
  text-align: center;
  margin-top: 30px;
  font-size: 20px;
  font-weight: 600;
}

.sales {
  margin-left: 110px;
}

.technical {
  margin-left: 20%;
}

.sales:hover, .technical:hover {
  box-shadow: 
      -15px -15px 10px -2px #C272FF,
      15px -15px 10px -2px #C272FF,
      0 -15px 10px -2px #C272FF,
      0 0 0 1px rgba(0, 0, 0, 0.1); 
}

/* Pf And ESIC */

.button-pf {
  background-color: #fff;
  border: 1px solid #ddd;
  color: gray;
  padding: 30px 15px 10px 10px;
  text-align: left;
  width: 850px;
  height: 40px;
  font-size: 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button-pf:hover {
  background-color: #f9f9f9;
}

.button-pf img {
  float: right;
  width: 20px;
  height: 20px;
}

.button-pf:not(:last-child) {
  margin-bottom: 10px;
}

/* booking & finance */

.payment-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding: 20px;
  max-width: 1000px;
  margin: auto;
  overflow-y: auto; 
}

.payment-button {
  border: 1px solid #ccc;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
  transition: transform 0.3s ease-in-out;
}

.payment-button:hover {
  transform: translateY(-5px);
}

.payment-button img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

/* hospital Locator */

.container-3 {
  background: rgb(206, 203, 244);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 75%;
  margin: 20px auto; 
}

.locator {
  width: 95%; 
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 19px;
  color: #666;
  margin-bottom: 15px;
  height: 35px;
}

.button-locator {
  background: #7E57C2;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 17px;
  border-radius: 20px;
  cursor: pointer;
  display: block;
  width: 30%; 
  margin: 15px auto 0;
}

.button-locator:hover {
  background: #673AB7;
}

/* view emergency number service */

.button-emergancy {
  background-color: #fff;
  border: 1px solid #ddd;
  color: gray;
  padding: 30px 15px 10px 10px;
  text-align: left;
  width: 850px;
  height: 40px;
  font-size: 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button-emergancy:hover {
  background-color: #f9f9f9;
}

.button-emergancy img {
  float: right;
  width: 20px;
  height: 20px;
}

.button-emergancy:not(:last-child) {
  margin-bottom: 10px;
}

.emergency-numbers {
  margin-top: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 10px;
  width: 92%;
}

.emergency-numbers ul {
  list-style-type: none;
  padding: 0;
}

.emergency-numbers ul li {
  margin-bottom: 5px;
  font-size: 16px;
  color: #333;
}

.emergency-numbers ul li:before {
  content: '\2022';
  color: #ff0000;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/* News & Community */

.card-container {
  display: flex;
  justify-content: space-around;
  margin: 20px;
}

.card {
  width: 45%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: box-shadow 0.3s;
  border: 1px solid #A29BFE;
}

.card img {
  width: 100%;
  display: block;
}

.card-content {
  padding: 20px;
  text-align: left;
}

.last-line {
  display: flex; 
  align-items: center; 
}

.last-line img {
  width: 40px;
  height: 40px;
  border-radius: 50%; 
  margin-right: 10px;
}

.last-line p {
  margin: 0 10px;
}

.blog {
  margin: 0;
  font-size: 18px;
}

.blg-heading {
  margin: 10px 0;
}

.card:hover {
  box-shadow: 
      -15px -15px 10px -2px #C272FF,
      15px -15px 10px -2px #C272FF,
      0 -15px 10px -2px #C272FF,
      0 0 0 1px rgba(0, 0, 0, 0.1); 
}

/* Training and Technology */

.custom-tabs {
  display: flex;
}

.custom-tab {
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px 5px 0 0;
  background-color: #f0f0f0;
}

.custom-tab.active {
  background-color: #fff;
}

.custom-tab-content {
  border: 1px solid #ccc;
  border-top: none;
  padding: 20px;
}

.videos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.video {
  width: 100%;
  max-width: 315px;
  margin-bottom: 20px;
}

video {
  width: 100%;
  height: auto;
  max-width: 315px;
  max-height: 200px;
  object-fit: cover;
}

.upload-section {
  margin-top: 20px;
}

.upload-input {
  margin-bottom: 10px;
}

.upload-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.upload-button:hover {
  background-color: #0056b3;
}

.video-not-approved {
  width: 100%;
  max-width: 315px;
  height: 0;
  padding-bottom: 63.5%; /* Maintains 16:9 aspect ratio */
  position: relative;
  background-color: #f0f0f0;
}

.video-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.not-approved-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: red;
  text-align: center;
  width: 100%;
}

.video-type-icon {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 24px;
  height: 24px;
}

.locate-me {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 4px;
}

.locate-me:hover {
  background-color: #0056b3;
}

/* Media Queries */

/* Large Desktops */
@media only screen and (min-width: 1200px) {
  .container-1, .container-3 {
      width: 60%;
  }
}

/* Desktops */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .container-1, .container-3 {
      width: 70%;
  }
}

/* Tablets and Small Desktops */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container-1, .container-3 {
      width: 80%;
  }

  .button-store, .button-locator {
      width: 40%;
  }

  .container-2 {
      flex-direction: column;
  }

  .sales, .technical {
      width: 60%;
      margin: 20px auto;
  }

  .button-pf, .button-emergancy {
      width: 90%;
  }

  .payment-container {
      grid-template-columns: repeat(3, 1fr);
  }

  .card-container {
      flex-direction: column;
  }

  .card {
      width: 80%;
      margin: 20px auto;
  }
}

/* Large Phones and Small Tablets */
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .container-1, .container-3 {
      width: 90%;
  }

  .button-store, .button-locator {
      width: 50%;
  }

  .container-2 {
      flex-direction: column;
  }

  .sales, .technical {
      width: 80%;
      margin: 20px auto;
  }

  .button-pf, .button-emergancy {
      width: 95%;
  }

  .payment-container {
      grid-template-columns: repeat(2, 1fr);
  }

  .card-container {
      flex-direction: column;
  }

  .card {
      width: 90%;
      margin: 20px auto;
  }
}

/* Small to Medium Phones */
@media only screen and (max-width: 575px) {
  .container-1, .container-3 {
      width: 95%;
  }

  .button-store, .button-locator {
      width: 70%;
  }

  .container-2 {
      flex-direction: column;
  }

  .sales, .technical {
      width: 90%;
      margin: 20px auto;
  }

  .button-pf, .button-emergancy {
      width: 100%;
  }

  .payment-container {
      grid-template-columns: repeat(1, 1fr);
  }

  .card-container {
      flex-direction: column;
  }

  .card {
      width: 95%;
      margin: 20px auto;
  }

  .custom-tabs {
      flex-direction: column;
  }

  .custom-tab {
      width: 100%;
      text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .video {
    max-width: 100%;
  }

  video {
    max-width: 100%;
    max-height: none;
  }

  .video-not-approved {
    max-width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .videos {
    flex-direction: column;
    align-items: center;
  }

  .video {
    width: 95%;
  }
}