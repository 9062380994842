body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  
  main{
    width: 100%;
    height: 90%;
  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #263238;
    color: #fff;
    height: 100px;
    border-radius: 0 0 20px 20px;
  }
  
  .logo img {
    margin-top: 30px;
    height: 140px; 
    width: 130px;
  }
  
  .navbar button {
    padding: 10px 20px 10px 20px;
    color: #fff;
    cursor: pointer;
    margin-right: 10px;
    font-size: 16px; 
  }
  
  .navbar span {
    color: #fff; 
  }
  
  #home-login-button {
    background: #A29BFE;
    border: 1px solid #A29BFE;
  }
  
  #home-signup-button {
    background: #F88379;
    border: 1px solid #F88379;
  }
  
  #home-login-button:focus {
    background: none;
    border: 1px solid #A29BFE;
    color: #A29BFE;
  }
  
  #home-signup-button:focus {
    background: none;
    border: 1px solid #F88379; 
    color: #F88379;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
    margin-right: 60px;
  }
  
  .dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    z-index: 1;
    border-radius: 5px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    transition: background-color 0.3s ease;
  }
  
  .dropdown-content a:hover {
    background-color: #ddd;
  }
  
  .dropdown.active .dropdown-content {
    display: block;
  }
  
  .dropdown-logo {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .dropdown-option {
    display: flex;
    align-items: center;
  }
  
  .dropdown-text {
    margin-right: 10px;
  }
  
  .dropdown-icon {
    transition: transform 0.3s ease;
  }
  
  .dropdown-content.active + .dropdown-logo .dropdown-icon {
    transform: rotate(180deg);
  }
  
  
  footer {
    background-color: #263238;
    color: #FFFFFF;
    text-align: center;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .footer-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-logo img {
    height: 140px; 
  
  }
  
  .footer-links,
  .footer-social {
    margin: 10px;
  }
  
  .footer-links a,
  .footer-social a {
    color: #FFFFFF;
    text-decoration: none;
    margin: 0 10px;
  }
  
  .footer-social img {
    width: 24px;
    height: 24px;
  }
  
  .footer-copyright {
    margin-top: 20px;
    font-size: 0.8em;
    border-top: 1px solid #4A4A4A;
    padding-top: 10px;
    text-align: right;
    margin-right: 150px;
  }
  
  .left-footer{
    display: grid;
  }
  
  .iframe {
    display: block;
    height: 90%;
  }
  
  .right-footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }

  .sidebar .active {
    color: #fff;
    background-color: #007bff;
    border-radius: 5px;
}
  
  .sidebar .asd.active {
    color: #fff;
    background-color: #dc3545;
  }

  /* login page css */

  .signup-link{

    text-align: center;
  }
  
  .login-link {
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
  }
  
  .dividerx {
    margin: 20px 0;
    width: 100%;
    height: 1px;
    background-color: black;
  }
  
  .others-option {
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .social-login {
    display: flex;
    justify-content: center;
  }
  
  .social-button {
    border: none;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin: 0 5px;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
  }
  
  .google {
    background: #dd4b39;
  }
  
  .linkedin {
    background: #0077b5;
  }
  
  .facebook {
    background: #3b5998;
  }

  .container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  
  .left-login-img {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
  
  .left-login-img img {
    max-width: 70%;
    height: auto;
  }
  
  .right-login-form {
    flex: 1;
    max-width: 500px;
    margin: 0 auto;
    margin-right: 60px;
  }
  
  .login-text {
    text-align: start;
    font-size: 30px;
    font-weight: 900;
    margin-bottom: 20px;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 540px;
  }

  .input-box {
    display: flex;
    align-items: center;
    border: 1px solid black;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 10px;
    width: 100%;
    background-color: #fff;
  }
  
  .input-box input {
    border: none;
    outline: none;
    flex: 1;
    padding: 10px;
    font-size: 17px;
  }
  
  .input-box img {
    margin-right: 10px;
    height: 30px;
  }
  
  .forgot-password {
    color: #0095f6;
    text-decoration: none;
    font-size: 0.9rem;
    margin-bottom: 20px;
    width: 100%;
    text-align: left;
  }
  
  .btn-btn {
    position: relative;
    background-color: #1ABC9C;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 24px;
    cursor: pointer;
    width: 40%;
    height: 45px;
    font-size: 1.2rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .btn-btn img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 35px;
  }

  .error-message {
    color: red;
  }

  button.logout-btn {
    background-color: red;
    border-radius: 5px;
    color: #fff;
    border-color: red;
}

  /* Media Queries */
/* For Mobile and up */
@media (max-width: 767px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
  
    .right-login-form {
      width: 90%;
      margin-top: 20px;
    }
  
    .left-login-img {
      margin-bottom: 20px;
    }
  
    .btn-btn img {
      width: 15px; 
    }
  }
  
  .job-post-form {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .job-post-form .form-group {
    margin-bottom: 15px;
  }
  
  .job-post-form .form-group label {
    display: block;
    font-weight: 500;
    margin-bottom: 5px;
  }
  
  .job-post-form .form-group input,
  .job-post-form .form-group select,
  .job-post-form .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .job-post-form .form-group textarea {
    resize: vertical;
  }
  
  .job-post-form .btn-submit {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .job-post-form .btn-submit:hover {
    background-color: #0056b3;
  }

  .success-message-job {
    color: green;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
  }
  .action-icon {
    margin-right: 10px; /* Adjust the value as needed */
  }
  .active-icon {
    color: green; /* Set the color for active status */
  }
  
  .inactive-icon {
    color: red; /* Set the color for inactive status */
  }


  /* CSS */
.job-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  background-color: #f9f9f9;
}

.job-details, .job-description-skills {
  flex: 1;
  padding: 16px;
}

.job-details {
  border-right: 1px solid #ccc;
}

.job-description-skills ul {
  list-style-type: disc;
  padding-left: 20px;
}

.btn-download {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 4px;
  background: #007bff;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.btn-download:hover {
  background: #0056b3;
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-left: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive styles for mobile devices */
@media (max-width: 768px) {
  .right-footer {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-links {
    margin-bottom: 20px;
  }

  .footer-links a {
    display: block;
    margin: 5px 0;
  }

  .footer-social {
    display: flex;
    justify-content: flex-start;
    margin-left: auto;
  }

  .footer-social a {
    margin-right: 10px;
  }

  .job-container {
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    background-color: #f9f9f9;
    display: block;
    width: 100%;
  }
}


@media only screen and (max-width: 768px) {

  .job-post-form {
    max-width: 50%;
}
.logo img {
  margin-top: 41px;
  height: 150px;
  width: 165px;
}

} 

@media only screen and (max-width: 480px) {

  .job-post-form {
    max-width: 50%;
}

.logo img {
  margin-top: 41px;
  height: 150px;
  width: 165px;
}
}



  
  
  