body, html {
    overflow-x: hidden;
    margin: 0;
    font-family: Arial, sans-serif;
    height: 100%;
  }
  
  
  .container {
    display: flex;
    justify-content: space-between;
    height: 100%;
  }
  
  .sidebar {
    width: 20%;
    height: 100%;
    background-color: #e9deef;
    padding: 20px;
    padding-top: 0;
    
  }
  
  .sidebar {
    width: 20%;
    height: 100%;
    background-color: #ffffff;
    padding: 20px;
    padding-top: 0;
    border-right: 2px solid whitesmoke;
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar ul li {
    padding: 10px 0;
    border-bottom: 1px solid black;
    position: relative;
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
  }

  .sidebar ul li a {
    text-decoration: none;
    color: #333;
    padding: 2px 16px;
    display: block;
}
  
  .sidebar ul li a:hover {
    /* Styles for hover state */
    background-color: #f0f0f0; /* Change background color on hover */
  }

  
  .sidebar ul li.selected {
    color: #F88379;
  }

  .sidebar-button {
    display: none;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .main-content {
    flex-grow: 1;
    padding: 20px;
    background-color: #fff;
  }
  
  .box {
    background-color: #eee;
    margin-bottom: 20px;
    padding: 20px;
    height: 100px;
  }
  
  .double-box-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 15px;
    column-gap: 30px;
  }
  
  .box.half {
    width: calc(50% - 10px);
  }
  
  .profile-area {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;
  }
  
  .notification {
    cursor: pointer;
    display: inline-block;
    margin-right: 20px;
  }
  
  .notification:hover .notification-popup {
    display: block;
  }
  
  .notification-popup {
    display: none;
    position: absolute;
    right: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 10px;
    width: 200px;
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .profile-text {
    margin-right: auto;
    font-size: 22px;
    font-weight:550;
    padding-right: 10px;
  }
  
  .profile-area img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: inline-block;
  }
  
  .sidebar-button {
    display: none;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .hide {
    display: none;
  }
  
  .sidebar ul li.selected {    
    color: #8a2be2;
  }
  
  iframe {
    display: block;
    height: 96%;
  }

  .change-password img, .edit-button img {
    height: 20px;
  }

  .profile-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 20px 0;
    background-color: #dedcff; 
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    margin: 0 auto;
  }
  
  .form-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }
  
  .form-group label {
    width: 30%;
    text-align: right;
    padding-right: 10px;
  }
  
  .form-group input {
    width: 65%;
    padding: 10px; 
    border: none;
    background-color: #f0f0f0;
    border-radius: 4px;
  }
  
  .edit-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 35px;
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-bottom: 20px;
  }
  
  .change-password {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    margin: 0 auto;
  }


  /* New Media Queries */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .container {
      flex-direction: column;
  }

  .sidebar {
      width: 100%;
      height: auto;
      display: none;
  }

  .sidebar.open {
      display: block;
  }

  .main-content {
      width: 100%;
      padding: 10px;
  }

  .profile-area {
    flex-direction: row; /* Change this from column to row */
    align-items: center; /* Center items vertically */
    justify-content: flex-end; /* Align items to the right */
    padding: 0 10px; /* Reduce padding */
  }

  .profile-text {
    margin-bottom: 0; /* Remove bottom margin */
    margin-right: 10px; /* Add some space between text and image */
    font-size: 16px; /* Make the font smaller on mobile */
  }

  .profile-area img {
    width: 40px; /* Make the image slightly smaller */
    height: 40px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .container {
      flex-direction: column;
  }

  .sidebar {
      width: 100%;
      height: auto;
      display: none;
  }

  .sidebar.open {
      display: block;
  }

  .main-content {
      width: 100%;
  }
  .profile-area {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 0 15px;
  }

  .profile-text {
    margin-bottom: 0;
    margin-right: 15px;
    font-size: 18px;
  }

  .profile-area img {
    width: 45px;
    height: 45px;
  }

}

/* Medium devices (landscape tablets, 769px and up) */
@media only screen and (min-width: 769px) and (max-width: 992px) {
  .sidebar {
      width: 30%;
  }

  .main-content {
      width: 70%;
  }
}

/* Large devices (laptops/desktops, 993px and up) */
@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .sidebar {
      width: 25%;
  }

  .main-content {
      width: 75%;
  }
}

/* Extra large devices (large laptops and desktops, 1201px and up) */
@media only screen and (min-width: 1201px) {
  .sidebar {
      width: 20%;
  }

  .main-content {
      width: 80%;
  }
}

/* Ensure sidebar is always visible on larger screens */
@media only screen and (min-width: 769px) {
  .sidebar {
      display: block !important;
  }

  .sidebar-button {
      display: none;
  }
}

/* Adjustments for grid items */
@media only screen and (max-width: 960px) {
  .MuiGrid-item {
      flex-basis: 100%;
      max-width: 100%;
  }
}

/* Additional adjustments */
@media only screen and (max-width: 768px) {
  .profile-form {
      width: 95%;
  }

  .form-group {
      flex-direction: column;
      align-items: flex-start;
  }

  .form-group label {
      width: 100%;
      text-align: left;
      margin-bottom: 5px;
  }

  .form-group input {
      width: 100%;
  }

  .bi-bell-fill{
    width: 25px;
    height: 30px;
  }

  .sidebar {
    display: none;
    width: 100%;
    position: absolute;
    top: 100px;
    left: 0;
    z-index: 1000;
    background-color: #e9deef; 
    padding: 20px;
  }

  .sidebar.open {
    display: block;
}

  .sidebar.active {
    display: block;
  }

  .sidebar ul li a {
    text-decoration: none;
    color: #333;
    padding: 2px 16px;
    display: block;
}
  
  .sidebar ul li a:hover {
    /* Styles for hover state */
    background-color: #f0f0f0; /* Change background color on hover */
  }

  .sidebar ul {
    list-style: none;
    padding: 0;
  }

  .sidebar ul li {
    padding: 10px 0;
    border-bottom: 1px solid black;
    position: relative;
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
  }
  .sidebar-button {
    display: block;
  }

  .container {
    position: relative;
  }
}

/* Ensure charts are responsive */
.recharts-responsive-container {
  width: 100% !important;
  height: 300px !important;
}
  

  